<template>
  <div class="min-h-[66px] border-r-2 border-blue-500"> </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.price-down::after {
  background-color: theme('colors.s-red.300');
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  content: '';
  mask: url('~/assets/icons/ChevronDown.svg?url');
  mask-repeat: no-repeat;
}
</style>
