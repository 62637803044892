<template>
  <NuxtLink
    v-if="!isMultiple"
    no-prefetch
    :to="group.items[0].url"
    class="bg-p-blue-200 text-p-yellow-500 hover:bg-p-yellow-500 hover:text-p-blue-200 h-fit rounded-full px-3 py-1 text-sm"
  >
    {{ group.items[0].label }}
  </NuxtLink>
  <div
    v-else
    :data-test="dataTest"
    class="bg-p-blue-200 text-p-yellow-500 hover:bg-p-yellow-500 hover:text-p-blue-200 group relative h-fit rounded-full px-3 py-1 text-sm"
  >
    <span>{{ group.label }}</span>
    <div
      :data-test="`${dataTest}-items`"
      class="absolute left-0 top-0 z-10 max-h-0 w-full overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:max-h-max"
    >
      <div class="bg-p-yellow-400 flex w-full flex-col gap-y-2 rounded-xl px-4 py-2.5 text-black">
        <NuxtLink
          v-for="(tag, index) in group.items"
          :key="index"
          :to="tag.url"
          no-prefetch
          class="hover:underline"
        >
          {{ tag.label }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    group?: any
  }>(),
  {
    group: () => null,
  },
)

const isMultiple = computed(() => {
  return props.group.isMultiple
})

const dataTest = computed(() => {
  const label = props.group.label.toLowerCase().replace(/\s/g, '-')
  return `page-title-tags-${label}`
})
</script>
