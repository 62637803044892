<template>
  <Card class="rounded-xl px-4 pb-4 pt-3" :class="[$attrs.class]">
    <div class="flex items-center justify-between">
      <div class="flex flex-wrap items-center gap-2">
        <h2 class="text-3xl font-semibold text-white" data-test="report-title">{{ title }}</h2>
        <span
          v-if="tagItems.length === 0 && subtitle"
          class="text-overview self-end text-2xl font-semibold"
          data-test="report-subtitle"
        >
          {{ subtitle }}
        </span>
        <div v-else class="flex flex-wrap gap-2" data-test="report-subtitle">
          <page-title-tags
            v-for="(group, groupIndex) in tagItems"
            :key="groupIndex"
            :group="group"
          />
        </div>
      </div>
      <div>
        <slot name="actions" />
      </div>
    </div>
    <div v-if="$slots.default && !isMobile" class="pt-2.5">
      <slot />
    </div>
  </Card>
</template>

<script setup lang="ts">
import useIsMobile from '~/composables/useIsMobile'

const { isMobile } = useIsMobile()

const props = withDefaults(
  defineProps<{
    subtitle?: string
    tags?: any[]
    title?: string
  }>(),
  {
    subtitle: '',
    tags: () => [],
    title: '',
  },
)

const tagItems = computed(() => {
  return [...props.tags].reverse()
})
</script>
