<template>
  <div class="text-center text-sm font-semibold text-white p-3 first:pl-0 last:pr-0 relative">
    <component
      :is="isWeighted !== undefined && isWeighted !== null ? TooltipWeighted : 'div'"
      class="text-sm text-p-yellow-500 whitespace-nowrap flex flex-col pb-2"
      :is-weighted="isWeighted"
    >
      <span
        v-for="(labelItem, index) in labelAsArray"
        :key="`label-${index}`"
        :class="labelClass"
        :data-test="dataTestid"
      >
        {{ labelItem }}
      </span>
    </component>
    <span
      v-if="value"
      :id="getTooltipId()"
      :class="[valueClass, { 'cursor-pointer': showTooltip }]"
      class="flex w-fit"
    >
      <ul
        v-if="variant === FactBoxItemTypes.LIST && value?.length >= 0"
        class="grid md:text-left gap-x-4"
        :class="`${value.length === 2 ? 'grid-cols-2 list-bullet' : ''} ${
          value.length > 2 ? 'grid-cols-3 list-bullet' : ''
        }`"
      >
        <li v-for="(item, index) in value" :key="index">
          {{ item }}
        </li>
      </ul>
      <template v-else>
        {{ value }}
      </template>
    </span>
    <div v-else :class="valueClass" class="flex w-fit">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import tippy, { followCursor, hideAll } from 'tippy.js'
import { computed } from 'vue'
import type { FactBoxItemProps } from './definitions'
import { FactBoxItemTypes } from './definitions'
import TooltipWeighted from '~/components/reports/TooltipWeighted.vue'

const props = withDefaults(defineProps<FactBoxItemProps>(), {
  isWeighted: undefined,
})

const tippyInstance = ref()

const labelAsArray = computed(() => {
  if (!props.label) return []

  if (Array.isArray(props.label)) {
    return props.label
  }

  return [props.label]
})

const getTooltipId = () => {
  if (!props.showTooltip) return

  return props.tooltipId
}

watch(props, ({ tooltipContent }) => {
  if (tippyInstance.value && tooltipContent) {
    tippyInstance.value.setContent(tooltipContent)
  }
})

onMounted(() => {
  if (props.showTooltip) {
    const tooltipContent = props.tooltipContent as string

    tippyInstance.value = tippy(`#${props.tooltipId}`, {
      placement: 'bottom',
      theme: 'weight',
      arrow: true,
      followCursor: true,
      plugins: [followCursor],
      allowHTML: true,
      content: tooltipContent,
    })[0]
  }
})

onBeforeUnmount(() => {
  hideAll()
})
</script>

<style scoped>
.list-bullet {
  list-style-type: none;
}

.list-bullet > li {
  @apply pl-4;

  position: relative;
}

.list-bullet > li::before {
  content: '•';
  position: absolute;
  left: calc(0.5rem - 2.3px);
}

.price-down {
  color: theme('colors.s-red.300');
}

.icon-price-down::after {
  background-color: theme('colors.s-red.300');
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  content: '';
  mask: url('~/assets/icons/ChevronDown.svg?url');
  mask-repeat: no-repeat;
}

.price-up {
  color: theme('colors.p-green.400');
}

.icon-price-up::after {
  background-color: theme('colors.p-green.400');
  rotate: theme('rotate.180');
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
  content: '';
  mask: url('~/assets/icons/ChevronDown.svg?url');
  mask-repeat: no-repeat;
}

.price-normal {
  color: theme('colors.n-gray.150');
}

.icon-price-normal::after {
  rotate: theme('rotate.180');
  display: block;
  background: theme('colors.n-gray.150');
  margin: auto;
  width: 15px;
  height: 15px;
  content: '';
  mask: url('~/assets/icons/minus.svg?url');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
</style>
